<template>
  <div class="page promotion-page">
    <Header />
    <HotlineMobileCard/>
    <div class="container" style="margin-top: 0; margin-bottom: auto;">
        <div class="promotion-filter mb--sm--3 mb--md--10">
          <div class="h5 font-weight--sm--500">Chương trình khuyến mãi</div>
          <el-select v-model="sortOptionSelected" placeholder="Chọn" v-on:change="sortPriceChangeHandle">
                <el-option
                  v-for="sortItem in sortOption"
                  :key="sortItem.value"
                  :label="sortItem.label"
                  :value="sortItem.value">
                </el-option>
            </el-select >
        </div>
        <div class="row">
          <div class="col col--sm--12 col--md--6 mb--sm--3 mb--md--8 flag" v-for="promotion in promotions" :key="promotion.promotionCode">
            <PromotionCard
              :promotionCode="promotion.promotionCode"
              :promotionName="promotion.promotionName"
              :thumbnail="promotion.thumbnail"
              :validFrom="promotion.validFrom"
              :validTo="promotion.validTo"
            />
          </div>
        </div>
        <div class="d--sm--flex justify-content--sm--center mt--sm--10 mb--sm--10" v-if="pagination.total > pagination.pageSize">
          <el-pagination
            @current-change="handlePaginationCurrentChange"
            :current-page="pagination.currentPage"
            :page-size="pagination.pageSize"
            layout="prev, pager, next"
            :total="pagination.total">
          </el-pagination>
        </div>
    </div>
    <Footer />
    <FooterMobile/>
  </div>
</template>

<script >
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import PromotionCard from '@/components/PromotionCard.vue'
import HotlineMobileCard from '@/components/HotlineMobileCard.vue'

export default {
  name: 'Promotions',
  components: {
    Header,
    Footer,
    FooterMobile,
    PromotionCard,
    HotlineMobileCard
  },
  data () {
    return {}
  },
  methods: {
    handlePaginationCurrentChange (currentPage) {
      this.$router.replace({ query: parseInt(currentPage) === 1 ? {} : { page: currentPage.toString() } })
      this.$store.commit('promotion/setPaginationCurrentPage', currentPage)
      this.$store.dispatch('promotion/getPromotions')
    },
    sortPriceChangeHandle: function () {
      this.$store.dispatch('promotion/getPromotions')
    }
  },
  computed: {
    pagination: {
      get () {
        return this.$store.state.promotion.pagination
      },
      set (pagination) {
        this.$store.commit('promotion/setPagination', pagination)
      }
    },
    sortOption () {
      return this.$store.state.promotion.sortOption
    },
    sortOptionSelected: {
      get () {
        return this.$store.state.promotion.sortOptionSelected
      },
      set (payload) {
        this.$store.commit('promotion/setSortOptionSelected', payload)
      }
    },
    promotions () {
      return this.$store.state.promotion.promotions
    }
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: false,
      headingText: '',
      back: false,
      search: true,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', true)
    this.$store.dispatch('promotion/getPromotions')
  }
}
</script>

<style lang="scss">
  @import "@/scss/respoinsive.scss";

  @include mobile() {
    .flag:nth-child(2n+1) {
      .promotion-card{
        background-color: #eff9f8;
      }
    }
  }
  @include tablet () {
    .flag:nth-child(4n + 1), .flag:nth-child(4n + 2)  {
      .promotion-card{
        background-color: #eff9f8;
      }
    }
  }
  .promotion-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-select {
      width: 100%;
      max-width: 130px;
    }
  }
</style>
