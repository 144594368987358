<template>
    <div class="promotion-card">
        <div class="promotion-card__icon" v-if="thumbnail">
           <img :src="thumbnail" alt="">
        </div>
        <div class="promotion-card__content">
            <div class="promotion-card__name" v-if="promotionName">{{promotionName}}</div>
            <div class="promotion-card__time" v-if="validFrom && validTo">Thời gian KM: {{$moment(validFrom).format('DD/MM/YYYY')}} - {{$moment(validTo).format('DD/MM/YYYY')}}</div>
            <div class="promotion-card__code" v-if="promotionCode">Code: {{promotionCode}}</div>
        </div>
        <div class="promotion-card__action">
            <HdButton v-on:click="$router.push('/promotion/' + promotionCode).catch(()=>{})" class="hd-button--promotion-card">
                Chi tiết
                <template v-slot:icon>
                    <ArrowRightIcon/>
                </template>
            </HdButton>
        </div>
    </div>
</template>

<script>
// Import Components
import HdButton from '@/components/HdButton'

// Import Icons
import ArrowRightIcon from '@/assets/arrow-right.svg'
export default {
  components: {
    HdButton,
    ArrowRightIcon
  },
  props: {
    promotionName: String,
    promotionCode: String,
    thumbnail: String,
    validFrom: String,
    validTo: String
  }
}
</script>

<style lang="scss">
    @import "@/scss/respoinsive";
    .promotion-card {
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid #003B35;
        border-radius: 15px;
        padding: 8px;
        height: 100%;
        @include tablet() {
            padding: 16px;
        }
        &__icon {
            margin-right: 8px;
            img {
                max-width: 70px;
                @include desktop() {
                    max-width: 150px;
                }
            }
            @include tablet() {
                margin-right: 16px;
            }
        }
        &__content {
            flex: 1;
            @include tabletDown() {
                padding-bottom: 16px;
            }
        }
    }
    .promotion-card__name {
        font-weight: 500;
    }
    .promotion-card__time {
        font-weight: 400;
    }
    .promotion-card__code {
        font-weight: 400;
    }
    .promotion-card__action {
        @include tabletDown() {
            position: absolute;
            right: 8px;
            bottom: 8px;
        }
    }
</style>
